import $ from 'jquery';
import { Datepicker } from 'vanillajs-datepicker';
import { OBSERVER } from '../plugins';
import { isVisible } from './helper';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

// Alerte
export function closeAlert() {
  const alert = document.querySelector('.c-alert');

  if (alert) {
    const close = () => {
      alert.classList.add('--closed');
      document.documentElement.style.setProperty('--alert-height', '0px');
    };
    const ifFooterIsVisible = () => {
      const footer = document.querySelector('.footer');

      if (isVisible(footer)) {
        alert.classList.add('--footer-is-visible');
      } else if (alert.classList.contains('--footer-is-visible')) {
        alert.classList.remove('--footer-is-visible');
      }
    };

    ifFooterIsVisible();

    OBSERVER.add({
      name: 'alert',
      events: 'click',
      function: close,
      targets: '[data-js="close-alert"]',
    });
    OBSERVER.add({
      name: 'alert',
      events: 'scroll',
      function: ifFooterIsVisible,
    });
    OBSERVER.on('alert');
  }
}

export function closeAlertMobile() {
  const alertMobile = document.querySelector('.c-alert-mobile');
  const alert = document.querySelector('.c-alert');

  if (alertMobile) {
    const close = () => {
      alert.classList.add('--closed');
      document.documentElement.style.setProperty('--alert-height', '0px');
    };

    OBSERVER.add({
      name: 'alert',
      events: 'click',
      function: close,
      targets: '[data-js="close-alert-mobile"]',
    });
    OBSERVER.on('alert');
  } else {
    document.documentElement.style.setProperty('--alert-height', '0px');
  }
}

// Positionne un élement selon un autre élément
export function positionReference(data) {
  const {
    variableName, reference, offsets,
  } = data;

  const referenceElement = document.querySelector(reference);
  const dimensionsAndPositions = {};

  const getDimensionsAndPosition = () => {
    for (let i = 0; i < offsets.length; i += 1) {
      const offsetValue = offsets[i].charAt(0).toUpperCase() + offsets[i].slice(1);

      dimensionsAndPositions[offsetValue] = referenceElement[`offset${offsetValue}`];
    }
  };

  const setPosition = () => {
    getDimensionsAndPosition();

    const keys = Object.keys(dimensionsAndPositions);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      document.documentElement.style.setProperty(`--${variableName}-${key}`, `${dimensionsAndPositions[key]}px`);
    }
  };

  setPosition();

  OBSERVER.add({
    name: 'setPosition',
    events: 'resize',
    function: setPosition,
  });
  OBSERVER.on('setPosition');
}
