import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import CharacterCount from '@blankonumerique/blanko-scripts/character-count';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  backendKeyBind, closeAlert, closeAlertMobile, ogContent, positionReference,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  overlayShare, overlaySearch, overlayDropdown, overlayMenu, overlayPopup,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import { hundredVH, removeHoverOnMobile } from './functions/helper';
import { clearInput, inputsAndTextareaLabel, tailSelect, changeSelect } from './functions/form';
import swiperHomeBanner from './functions/swiper';
import tippyPhotoCredit from './functions/photoCredit';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';
import { formNewsletter } from './functions/validation';

const initBarba = () => {
  barba.hooks.beforeEnter(async ({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    overlayShare();
    overlaySearch();
    overlayDropdown();
    overlayPopup();
    overlayMenu();
    hundredVH();
    backendKeyBind();
    ShareButtons.update();
    SCROLLFIRE.init();
    CharacterCount.init();
    closeAlert();
    closeAlertMobile();
    inputsAndTextareaLabel();
    formNewsletter();
    clearInput();
    await Scroller.init();
    removeHoverOnMobile();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        async beforeEnter() {
          rubrics();
          dynamicPackage();
          tippyPhotoCredit();

          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          tippyPhotoCredit();
          swiperHomeBanner('[data-swiper="home-banner"]');
          positionReference({
            variableName: 'pass-card',
            container: '.c-home-banner',
            reference: '.c-home-banner__picture-container',
            offsets: ['height', 'top'],
          });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsView',
        beforeEnter() {
          tailSelect();
          tippyPhotoCredit();
          changeSelect('#newsCategoryFilter');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          tippyPhotoCredit();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect();
          changeSelect('#searchFilterSelect');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
