import { OBSERVER } from '../plugins';

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      let k;
      const { styleSheets } = document;
      const styleSheetsLength = styleSheets.length;
      for (k = 0; k < styleSheetsLength; k += 1) {
        // for (const si in document.styleSheets) {
        const styleSheet = styleSheets[k];
        if (styleSheet.rules) {
          for (let ri = styleSheet.rules.length - 1; ri >= 0; ri -= 1) {
            if (styleSheet.rules[ri].selectorText) {
              if (styleSheet.rules[ri].selectorText.match(':hover')) {
                const { selectorText } = styleSheet.rules[ri];
                const araySelectorText = selectorText.split(',');
                let newSelectorText = '';

                // Garder slm les selectorText no :hover
                for (let i = 0; i < araySelectorText.length; i += 1) {
                  if (!araySelectorText[i].match(':hover')) {
                    newSelectorText += newSelectorText !== '' ? `, ${araySelectorText[i]}` : ` ${araySelectorText[i]}`;
                  }
                }

                // Enlver la règle si la seule classe est un :hover
                if (newSelectorText === '') {
                  styleSheet.deleteRule(ri);
                } else {
                  styleSheet.rules[ri].selectorText = newSelectorText;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

// Permet de donner la hauteur exact en mobile de 100vh
// Css -> height: calc(var(--vh, 1vh) * 100);
export function hundredVH() {
  function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  OBSERVER.add({
    name: 'hundredVH',
    events: 'resize load',
    targets: 'window',
    function: setHeight,
  });
  OBSERVER.on('hundredVH');
}

// Retourne si un élément est visible dans le viewport
export function isVisible(el) {
  const rect = el.getBoundingClientRect();

  const bottom = rect.bottom >= 0;
  const right = rect.right >= 0;
  const top = rect.top <= (window.innerHeight || document.documentElement.clientHeight);
  const left = rect.left <= (window.innerWidth || document.documentElement.clientWidth);

  return bottom && right && top && left;
}
