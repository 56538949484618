/*
  Cette classe est utilisé pour gérer les filtres de categorie et subcategorie

*/

import $ from 'jquery'
import {HTMLMarker} from './mapMarker'
import {mapUrl} from './mapUrl'

export class mapFilter {


  /**
   * Gestion des filtrations
   */
  static init() {

    // Bouton see all.
    $('.js-mapRoot').on('click', function (e) {
      mapUrl.setSlugCategory(null)
      //mapFilter.closeAllSubCategory()
      //mapFilter.resetUISubCategory()
      mapFilter.resetUItoRoot()
      HTMLMarker.showAll()
      HTMLMarker.fitBounds()
      mapFilter.setCategoryName(null)
      e.preventDefault()
    })

    // si click sur un lien de categori de base
    $('.js-mapRootCategory').on('click', function (e) {

      let categoryID = $(e.currentTarget).data('category')
      let slug = $(e.currentTarget).data('slug')

      if ( mapUrl.getSlugCategory() != slug ) {

        // Le current slug est différent du target.
        // On switch de catégorie.

        mapUrl.setSlugCategory(slug)
        HTMLMarker.showCategory(categoryID)
        mapFilter.setCategoryName(categoryID)
        HTMLMarker.fitBounds()

      } else if ( mapUrl.getOnLoadSlugCategory() != slug ) {

        // Le current slug est identique au target.
        // Hors, le onLoad Slug est différent (et donc à null).
        // Il s'agit donc d'un toggle click (fermeture d'un root catégorie).
        // On affiche tout.

        mapUrl.setSlugCategory(null)
        mapFilter.setCategoryName(null)
        HTMLMarker.showAll()
        HTMLMarker.fitBounds()

      } else {

        // Le current slug est identique au target, et le onLoad Slug aussi.
        // Il s'agit donc d'un loading de page.
        // On affiche donc la catégorie associé et on reset le onLoadSlug.

        mapUrl.resetOnLoadSlugCategory()
        mapUrl.resetOnLoadSlugSubCategory()
        HTMLMarker.showCategory(categoryID)
        mapFilter.setCategoryName(categoryID)
        HTMLMarker.fitBounds()
      }

      mapFilter.closeAllSubCategory()
      mapFilter.resetUISubCategory()
      e.preventDefault()
    })

    // si click sur un lien de sous-categorie
    $('.js-mapSubCategory').on('click', function (e) {

      mapFilter.resetUISubCategory()
      mapFilter.activateUISubCategory(e.currentTarget)

      let categoryID = $(e.currentTarget).data('category')
      let slug = $(e.currentTarget).data('slug')

      HTMLMarker.showCategory(categoryID)
      mapUrl.setSlugSubCategory(slug)
      HTMLMarker.fitBounds()
      mapFilter.setCategoryName(categoryID)
      e.preventDefault()
    })

    // ecouteur de POI activé
    // lorsqu’un POI est activé, le sous menu correspondant est activé.
    window.addEventListener('marker.activated', (e) => {

      $('.poi.active').removeClass('active')
      let idObject = '.poi-' + e.detail.slug + '-' + e.detail.id
      $(idObject).addClass('active')
    })

    mapFilter.autoOpenCategorie()
  }

  static autoOpenCategorie() {

    mapFilter.resetUISubCategory()

    // ouvre les tirroirs et le reste
    if (mapUrl.getOnLoadSlugSubCategory() != undefined)
    {
      // Ouverture categorie.
      let catSelector = '#overlayLocations .btnSubLvl1 > [data-slug="' + mapUrl.getOnLoadSlugCategory() + '"]'
      if ( $(catSelector).length ) {
        let categoryID = $(catSelector).data('category')
        $('.subLvl1', $(catSelector).parent()).addClass('open').show()
        $('#overlayLocations .structure').addClass('one-is-open')
        mapFilter.activateUISubCategory(catSelector)

        // Ouverture sub categorie.
        catSelector = '#overlayLocations .btnSubLvl2 > [data-slug="' + mapUrl.getOnLoadSlugSubCategory() + '"]'

        if ($(catSelector).length) {
          categoryID = $(catSelector).data('category')
          $('.subLvl2', $(catSelector).parent()).addClass('open').show()
          $('#overlayLocations .btnSubLvl1.active .subLvl1').addClass('one-is-open')
          mapFilter.activateUISubCategory(catSelector)
        }

        // Show category markers
        HTMLMarker.showCategory(categoryID)
        mapFilter.setCategoryName(categoryID)
      }
    }
    else if (mapUrl.getOnLoadSlugCategory() != undefined) {

      let catSelector = '#overlayLocations .btnSubLvl1 > [data-slug="' + mapUrl.getOnLoadSlugCategory() + '"]'

      if ( $(catSelector).length )
      {
        let categoryID = $(catSelector).data('category')

        $('.subLvl1', $(catSelector).parent()).addClass('open').show()
        $('#overlayLocations .structure').addClass('one-is-open')
        mapFilter.activateUISubCategory(catSelector)
        mapFilter.setCategoryName(categoryID)

        // Show category markers
        HTMLMarker.showCategory(categoryID)
      }
      else
      {
        HTMLMarker.showAll()
      }
    }
    else
    {
      // ROOT
      HTMLMarker.showAll()
    }

    mapUrl.resetOnLoadSlugCategory()
    mapUrl.resetOnLoadSlugSubCategory()
  }

  static openCategorieByUrl() {

    mapFilter.resetUISubCategory()

    if (mapUrl.getSlugSubCategory() != undefined)
    {
      // Sub cat
      let catSelector = '#overlayLocations .btnSubLvl2 > [data-slug="' + mapUrl.getSlugSubCategory() + '"]'
      let categoryID = $(catSelector).data('category')
      HTMLMarker.showCategory(categoryID)
      mapFilter.setCategoryName(categoryID)
    }
    else if (mapUrl.getSlugCategory() != undefined) {
      // Cat
      let catSelector = '#overlayLocations .btnSubLvl1 > [data-slug="' + mapUrl.getSlugCategory() + '"]'
      let categoryID = $(catSelector).data('category')
      HTMLMarker.showCategory(categoryID)
      mapFilter.setCategoryName(categoryID)
    }
    else
    {
      // root
      HTMLMarker.showAll()
    }
  }

  static setCategoryName(idCategory) {

    if (idCategory == null)
    {
      $('#mapCategoryName').html('Catégories')
    }
    else
    {
      let catName = $('#overlayLocations span[data-category="' + idCategory+ '"]').text()
      $('#mapCategoryName').html($.trim(catName))
    }
  }

  static resetUItoRoot() {

    $('#overlayLocations .btnSubLvl1, #overlayLocations .btnSubLvl2, .poi.active').removeClass('active')
    $('#overlayLocations .subLvl1, #overlayLocations .subLvl2').animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
    $('#overlayLocations .subLvl1, #overlayLocations .subLvl2').removeClass('open')

  }

  // reset le UI des liens de sous-category
  static resetUISubCategory() {

    // Do by extern js.
    $('.drawers .active').removeClass('active')
    $('.poi.active').removeClass('active')
  }

  static closeAllSubCategory() {

    // Do by extern js.
    $('#drawers .drawersLVL2 > li.open > .drawer').animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    $('#drawers .drawersLVL2 > li.open').removeClass('open')
  }

  static activateUISubCategory(targetSelector) {
    $(targetSelector).parent().addClass('active')
  }

  // selectionne les sous-menu et active le POI
  static activatePOI(slug, id) {

    HTMLMarker.activate(id,slug)
  }
}

window.mapFilter = mapFilter
