import barba from '@barba/core';
import Swiper from 'swiper/bundle';

export default function swiperHomeBanner(swiperElement) {
  const swiper = document.querySelector(`${swiperElement}`);

  if (swiper) {
    window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
      speed: 700,
      autoplay: {
        delay: 7000,
      },
      loop: (document.querySelectorAll('[data-swiper="home-banner"] .swiper-slide').length > 1),
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '[data-swiper="home-banner-pagination"]',
        type: 'bullets',
        clickable: true,
      },
    });

    barba.hooks.afterLeave(() => {
      window['home-banner'].destroy();
    });
  }
}
