import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';

export function overlayShare() {
  const overlay = document.querySelector('.c-overlay-share');

  if (overlay) {
    const ovShare = new Overlay({
      name: 'share',
      click: {
        buttons: {
          open: '.js-open-overlay-share',
          close: '.js-close-overlay-share',
        },
      },
      options: {
        speed: 800,
      },
    });

    ovShare.init();
  }

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    // alert('L’adresse URL a bien été copiée dans votre presse-papier.');
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  const openShareOverlay = (e) => {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  };

  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-open-overlay-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlaySearch() {
  const overlay = document.querySelector('.c-overlay-search');

  if (overlay) {
    const search = new Overlay({
      name: 'search',
      events: {
        openComplete: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          open: '[data-js="open-overlay-search"]',
          close: '[data-js="close-overlay-search"]',
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
        goToSelector: 'html, body',
      },
    });

    search.init();
  }

  const searchButton = document.querySelector('[data-js="open-overlay-search"]');
  const inputSearch = document.getElementById('form-search-input');

  let inputValue;

  setTimeout(() => {
    inputValue = document.querySelector('.c-overlay-search [data-inputvalue]');
  }, 100);

  const onClick = () => {
    if (inputSearch) {
      inputSearch.focus();
    }
  };

  const onCloseComplete = () => {
    inputSearch.value = '';
    inputValue.setAttribute('data-inputvalue', '');
  };

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    function: onClick,
    targets: searchButton,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onCloseCompleteOverlaySearch',
    function: onCloseComplete,
  });
  OBSERVER.on('overlaySearch');
}

export function overlayDropdown() {
  const overlay = document.querySelector('.c-overlay-dropdown');

  if (overlay) {
    new Overlay({
      name: 'dropdown',
      click: {
        buttons: {
          trigger: '[data-js="trigger-overlay-dropdown"]',
          close: '[data-js="close-overlay-dropdown"]',
          switch: '[data-js="open-overlay-search"]',
        },
      },
      animations: {
        selector: '.c-overlay-dropdown__sections',
        styles: [{
          property: 'height',
          value: 'dataset',
          easing: 'easeInOutQuart',
        }],
      },
      options: {
        speed: 1000,
        closeOnResize: true,
      },
      events: {
        close: true,
        trigger: true,
      },
    }).init();
  }
}

export function overlayMenu() {
  const overlay = document.querySelector('.c-overlay-menu');

  if (overlay) {
    window.overlayMenu = new Overlay({
      name: 'menu',
      events: {
        openComplete: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          trigger: '[data-js="trigger-overlay-menu"]',
          close: '[data-js="close-overlay-menu"], a[href]',
          switch: '[data-js="open-overlay-search"]',
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
        goToSelector: 'html, body',
      },
    });

    window.overlayMenu.init();
  }

  const html = document.querySelector('html');
  const hamburgerButton = document.querySelector('.c-button-hamburger');

  const onCloseComplete = () => {
    hamburgerButton.dataset.section = '';
    hamburgerButton.dataset.section = 'primary';
  };

  const changeButtonData = () => {
    const { section } = html.dataset;

    hamburgerButton.dataset.section = '';
    hamburgerButton.dataset.section = `${section}`;
  };

  OBSERVER.add({
    name: 'menu',
    events: 'click',
    targets: '.c-overlay-menu__primary-button, .c-overlay-menu__back-button',
    function: changeButtonData,
  });
  OBSERVER.add({
    name: 'menu',
    events: 'onCloseCompleteOverlayMenu',
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: 'menu',
    events: 'scroll',
    targets: '.c-overlay-menu__section-content',
  });

  OBSERVER.on('menu');
}

export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    const ovPopup = new Overlay({
      name: 'popup',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '[data-js="close-overlay-popup"]',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
      },
    });

    ovPopup.init();

    // Fonction permettant de donner un attribut height au container de l'overlay
    const addHeightAttributeToContainer = (nameEventOverlay, targetContainerOverlay) => {
      const containerOverlay = document.querySelector(targetContainerOverlay);

      function onOpen() {
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      function onCloseComplete() {
        containerOverlay.style.height = null;
      }

      function onResize() {
        containerOverlay.style.height = null;
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onOpenOverlay${nameEventOverlay}`,
        function: onOpen,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onCloseCompleteOverlay${nameEventOverlay}`,
        function: onCloseComplete,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: 'resize',
        function: onResize,
      });
      OBSERVER.on(`overlay${nameEventOverlay}`);
    };

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer('Popup', '[data-overlay-container="popup"]');
  }
}
